import React from "react"
import styled from "styled-components"
import ArrowRightIcon from "../Icons/ArrowRightIcon"
import BreakpointUp from "../Media/BreakpointUp"
import { StaticImage } from "gatsby-plugin-image"

const Card = styled.div`
  background-color: #fff;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  border: 2px solid #c1d1e3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  text-align: center;
  min-height: 136px;
  &.active {
    box-shadow: 0 20px 30px rgb(0 0 0 / 20%);
    button {
      svg {
        fill: #000;
      }
    }
  }
`
const CardFigure = styled.figure`
  margin: 0 0 8px;
  display: flex;
  align-items: flex-end;
  .gatsby-image-wrapper {
    display: inline-block;
  }
`
const CardLead = styled.div`
  margin-bottom: 0;
  color: #666;
  line-height: 20px;
  font-size: 14px;
`
const CardTitle = styled.div`
  margin-bottom: 0;
  color: #033366;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 20px;
`
const CardBody = styled.div`
  position: relative;
  z-index: 1;
  padding: 15px;
`
const CardAction = styled.div`
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  left: 50%;
  transform: translateX(-50%);
  bottom: -15px;
  box-shadow: 0 3px 0px #c1d1e3;
  ${BreakpointUp.lg`
        left: -15px;
        transform: translateX(0);
        bottom:auto;
        box-shadow:-3px 0px 0px #c1d1e3;
    `}

  button {
    width: 100%;
    height: 100%;
    transform: rotate(90deg);
    ${BreakpointUp.lg`
        transform: rotate(180deg);
        `}
  }
  svg {
    fill: #d51333;
  }
  &:hover,
  &:focus {
    svg {
      fill: #000;
    }
  }
`

const CardStyle = props => {
  const { roofStyle, styleTitle, styleLead } = props
  return (
    <Card className="card">
      <CardBody>
        <CardFigure className="card-img">
          {roofStyle === "Vertical Roof" && (
            <StaticImage
              src="../../images/vertical-roof-style.png"
              placeholder="blurred"
              alt="Vertical Roof Style"
            />
          )}
          {roofStyle === "A-FRAME" && (
            <StaticImage
              src="../../images/a-frame-roof-style.png"
              placeholder="blurred"
              alt="Vertical Roof Style"
            />
          )}
          {roofStyle === "Regular Roof" && (
            <StaticImage
              src="../../images/regular-roof-style.png"
              placeholder="blurred"
              alt="Vertical Roof Style"
            />
          )}
        </CardFigure>
        <CardTitle>{styleTitle}</CardTitle>
        <CardLead>{styleLead}</CardLead>
      </CardBody>
      <CardAction>
        <button type="button" aria-label="button">
          <ArrowRightIcon />
        </button>
      </CardAction>
    </Card>
  )
}

export default CardStyle
