import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import PrimaryButton from "../Button/PrimaryButton"
import BreakpointUp from "../Media/BreakpointUp"
import Badge from "../Badge"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import BreakpointDown from "../Media/BreakpointDown"

const Card = styled.div`
  position: relative;
  background-color: #fff;
  box-shadow: 0 75px 155px rgba(0, 75, 155, 0.15);
  margin-bottom: 30px;
  border-radius: 6px;
  text-align: center;
  height: calc(100% - 30px);
`
const CardFigure = styled.figure`
  position: relative;
  margin: 0;
  overflow: hidden;
  > .gatsby-image-wrapper {
    transition: transform 0.5s ease;
    transform-origin: center;
  }
  &:hover {
    > .gatsby-image-wrapper {
      transform: scale(1.1);
    }
  }
  /* .main-image {
    height: 250px;
  } */
  .gatsby-image-wrapper {
    border-radius: 6px 6px 0 0;
    display: block;
    > div {
      max-width: 100% !important;
    }
  }
`
const CardBody = styled.div`
  position: relative;
  padding: 30px;
  ${BreakpointUp.xxl`
    padding:30px 60px 40px 60px;
  `}
`
const CardTitle = styled.div`
  a {
    color: #000;
    &:hover,
    &:focus {
      color: #033366;
    }
  }
`
const CardToolbar = styled.div`
  margin-bottom:10px;
  > a,
  > button {
    display: block;
    width: 100%;
  }
  .btn {
    width: 100%;
    ${BreakpointDown.sm`
     .icon{
       display:none;
     }
    `}
  }
`
const CardPrice = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px -7px 10px -7px;
  justify-content: center;
  strong {
    color: #d51333;
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    letter-spacing: 0.3px;
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    line-height: 24px;
    ${BreakpointUp.md`
      font-size: 20px;
      line-height: 24px;
    `}
    ${BreakpointUp.xxl`
      font-size: 22px;
      line-height: 28px;
    `}
  }
  span {
    font-size: 14px;
    line-height: 20px;
    color: #4c4f52;
    display: inline-block;
    vertical-align: middle;
    ${BreakpointDown.sm`
     font-size:12px;
    `}
    + strong {
      margin-left: 4px;
      ${BreakpointDown.sm`
     font-size:14px;
    `}
    }
  }
`
const StartPrice = styled.div`
  position: relative;
  padding: 0 7px;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  ${BreakpointDown.sm`
    padding:0 2px;
  `}
`
const MonthlyPrice = styled.div`
  position: relative;
  padding: 0 7px;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  ${BreakpointDown.sm`
    padding:0 2px;
  `}
`
const CardBadge = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -4px;
  .badge {
    margin: 0 4px;
    font-size: 14px;
    padding: 4px 15px;
    > .text {
      margin: 0 auto;
    }
    &:hover,
    &:focus {
      background-color: transparent;
      color: #eba300;
    }
  }
`
const CardHelp = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom:10px;
`

const CardAction = styled.div`
  span {
    font-size: 14px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }
  a {
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    white-space: nowrap;
    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 24px;
    }
    @media (min-width: 1600px) {
      font-size: 22px;
      line-height: 28px;
    }
    color: #d51333;
    &:hover,
    &:focus {
      color: #033366;
    }
  }
`
const Measurement = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 520px;
  width: 100%;
  margin: 0 auto;
  padding: 15px 10px;
  ${BreakpointDown.sm`
    padding:15px 6px;
  `}
`
const MeasurementItem = styled.div`
  position: relative;
  width: 100%;
  padding: 0 5px;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;

  display: flex;
  align-items: center;
  ${BreakpointDown.sm`
    padding:0 2px;
  `}
  .icon {
    width: 50px;
    + .text {
      margin-left: 10px;
      ${BreakpointDown.sm`
        margin-left:4px;
      `}
    }
  }
  .text {
    text-align: left;
    line-height: 20px;
    small,
    strong {
      display: block;
      line-height: 20px;
      font-weight: 700;
      ${BreakpointDown.sm`
       line-height: 15px;
      `}
    }
    small {
      font-size: 12px;
      color: #fff;
    }
    strong {
      font-size: 12px;
      color: #d59300;
    }
  }
`
const InfoTip = styled.div`
  background-color: ${props => props.bgColor};
  padding: 4px 20px;
  border-radius: 4px 0 4px 0;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  color: #fff;
`

const ProductCard = props => {
  let bgColor = ""
  const { roof, data } = props
  let filtertype = ""
  switch (roof) {
    case "Regular":
      filtertype = "Good"
      bgColor = "#D59300"
      break
    case "A-Frame":
      filtertype = "Better"
      bgColor = "#033366"
      break
    case "Vertical":
      filtertype = "Best"
      bgColor = "#D51333"
      break
    default:
      filtertype = ""
      bgColor = ""
  }

  if (data) {
    const productImage = getImage(data.image)
    return (
      <Card className="product-card">
        <CardFigure className="card-img">
          <InfoTip bgColor={bgColor}>{filtertype}</InfoTip>
          <GatsbyImage
            className="main-image"
            image={productImage}
            alt={data.name}
          />
          <Measurement>
            <MeasurementItem>
              <span className="icon">
                <StaticImage
                  src="../../svg/width.svg"
                  placeholder="blurred"
                  alt="width"
                />
              </span>
              <span className="text">
                <small>Width</small>
                <strong>{data.width}</strong>
              </span>
            </MeasurementItem>
            <MeasurementItem>
              <span className="icon">
                <StaticImage
                  src="../../svg/length.svg"
                  placeholder="blurred"
                  alt="length"
                />
              </span>
              <span className="text">
                <small>Length</small>
                <strong>{data.length}</strong>
              </span>
            </MeasurementItem>
            <MeasurementItem>
              <span className="icon">
                <StaticImage
                  src="../../svg/height.svg"
                  placeholder="blurred"
                  alt="height"
                />
              </span>
              <span className="text">
                <small>Height</small>
                <strong>{data.height}</strong>
              </span>
            </MeasurementItem>
          </Measurement>
        </CardFigure>
        <CardBody className="card-body">
          <CardTitle className="card-title h6">
            <Link to={data.url}>{data.name}</Link>
          </CardTitle>
          <CardBadge>
            {data.availableFinancing && <Badge text="FINANCING" />}
            {data.availableRto && <Badge text="RTO" />}
          </CardBadge>
          <CardPrice>
            <StartPrice>
              <span>Starting Price :</span>
              <strong>${data.price}</strong>
            </StartPrice>
            {/* <MonthlyPrice>
              <span>Monthly Payment :</span>
              <strong>$110.00</strong>
            </MonthlyPrice> */}
          </CardPrice>
          <CardHelp>*Prices Vary With States And Certification Requirements</CardHelp>
          <CardToolbar>
            <button
              type="button"
              aria-label="button"
              onClick={props.openQuoteModal}
              >
              <PrimaryButton text="Request a Quote" />
            </button>
          </CardToolbar>
          <CardAction>
            <span>If You Need Any Assistant, Please Call:</span>{" "}
            <a href="tel:8004407309">(800) 440-7309</a>
          </CardAction>
            
        </CardBody>
      </Card>
    )
  } else {
    return (
      <Card className="product-card">
        <CardFigure className="card-img">
          <InfoTip bgColor={bgColor}>{filtertype}</InfoTip>
          <StaticImage
            src="../../images/p01.jpg"
            placeholder="blurred"
            alt="material-metal-carports"
          />
          <Measurement>
            <MeasurementItem>
              <span className="icon">
                <StaticImage
                  src="../../svg/width.svg"
                  placeholder="blurred"
                  alt="width"
                />
              </span>
              <span className="text">
                <small>Width</small>
                <strong>50</strong>
              </span>
            </MeasurementItem>
            <MeasurementItem>
              <span className="icon">
                <StaticImage
                  src="../../svg/length.svg"
                  placeholder="blurred"
                  alt="length"
                />
              </span>
              <span className="text">
                <small>Length</small>
                <strong>60</strong>
              </span>
            </MeasurementItem>
            <MeasurementItem>
              <span className="icon">
                <StaticImage
                  src="../../svg/height.svg"
                  placeholder="blurred"
                  alt="height"
                />
              </span>
              <span className="text">
                <small>Height</small>
                <strong>16</strong>
              </span>
            </MeasurementItem>
          </Measurement>
        </CardFigure>
        <CardBody className="card-body">
          <CardTitle className="card-title h6">
            <Link to="/">50X61x16 Vertical Roof & Wall Garage</Link>
          </CardTitle>
          <CardBadge>
            <Badge text="RTO" /> <Badge text="Financing" />
          </CardBadge>
          <CardPrice>
            <StartPrice>
              <span>Starting Price :</span>
              <strong>$8485.00</strong>
            </StartPrice>
            <MonthlyPrice>
              <span>Monthly Payment :</span>
              <strong>$110.00</strong>
            </MonthlyPrice>
          </CardPrice>
          <CardHelp>*Prices Vary With States And Certification Requirements</CardHelp>
          <CardToolbar>
            <button type="button" aria-label="button">
              <PrimaryButton text="Request a Quote" />
            </button>
          </CardToolbar>
          <CardAction>
            <span>If You Need Any Assistant, Please Call:</span>{" "}
            <a href="tel:8004407309">(800) 440-7309</a>
          </CardAction>
        </CardBody>
      </Card>
    )
  }
}

export default ProductCard
